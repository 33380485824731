/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';

import createGet from '../utils/create-get';
import useCurrentLanguage from '../hooks/use-current-language';

import headerMarketsSvg from '../img/headers/header-markets.svg';

import Layout from '../components/Layout';
import Container from '../components/Container2';
import AspectRatioImg from '../components/AspectRatioImg';
import MarkdownContent from '../components/MarkdownContent';
import solutionsDataSvg from '../img/illustrations/markets-solutions-data.svg';
import solutionsBuildSvg from '../img/illustrations/markets-solutions-build.svg';
import solutionsPredictSvg from '../img/illustrations/markets-solutions-predict.svg';
import hospitalsSvg from '../img/illustrations/hospitals.svg';
import solutionsModuleDecisionFrSvg from '../img/illustrations/solutions-module-decision-fr.svg';

const imageMap = {
  data: solutionsDataSvg,
  build: solutionsBuildSvg,
  predict: solutionsPredictSvg,
  'predict-fr': solutionsModuleDecisionFrSvg,
  hospitals: hospitalsSvg,
};
const Card = ({ imageKey, imageLabel, path }) => (
  <Link sx={{ textDecoration: 'none', color: 'black' }} to={path}>
    <div
      sx={{
        display: ['none', null, 'block'],
        border: 'solid',
        borderColor: 'gray1',
        borderWidth: '12px',
        width: 'min-content',
        height: 'min-content',
      }}
    >
      <AspectRatioImg
        src={imageMap[imageKey]}
        sx={{ bg: 'gray1' }}
        alt=""
        ratio={250 / 250}
        width={250}
      />
      <div
        sx={{
          display: ['none', null, 'flex'],
          justifyContent: 'center',
          py: 2,
          backgroundColor: 'gray1',
          pb: '-12px',
        }}
      >
        {imageLabel}
      </div>
    </div>
  </Link>
);

const ModuleContent = ({ get, module }) => (
  <div>
    <Styled.h4 sx={{ fontWeight: 'bold', color: '#6F39D3' }}>
      {get(module, 'title')}
    </Styled.h4>
    <MarkdownContent sx={{ mt: 7 }}>{get(module, 'text')}</MarkdownContent>
  </div>
);

const ModuleIllustration = ({ imageKey }) => (
  <div sx={{ display: ['none', null, 'block'] }}>
    <AspectRatioImg
      src={imageMap[imageKey]}
      alt=""
      ratio={440 / 203}
      width={'440px'}
    />
  </div>
);

export const MarketsPageTemplate = ({ get }) => (
  <Fragment>
    <section>
      <Container>
        <AspectRatioImg src={headerMarketsSvg} alt="" ratio={912 / 256} />
        <div sx={{ my: 7 }}>
          <Styled.h3>{get('challenges.title')}</Styled.h3>
          <MarkdownContent sx={{ mt: 7 }}>
            {get('challenges.text')}
          </MarkdownContent>
        </div>
        <div>
          <Styled.h3>{get('solutions.title')}</Styled.h3>
          <MarkdownContent sx={{ mt: 7 }}>
            {get('solutions.text')}
          </MarkdownContent>
          <div
            sx={{
              display: 'grid',
              gridAutoFlow: 'rows',
              gridGap: 13,
              mt: 13,
            }}
          >
            {get('solutions.modules', []).map((module, i) => (
              <article
                key={i}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', null, '1fr 1fr'],
                  gridGap: 7,
                }}
              >
                {i % 2 === 0 ? (
                  <Fragment>
                    <ModuleIllustration imageKey={get(module, 'imageKey')} />
                    <ModuleContent get={get} module={module} />
                  </Fragment>
                ) : (
                  <Fragment>
                    <ModuleContent get={get} module={module} />
                    <ModuleIllustration imageKey={get(module, 'imageKey')} />
                  </Fragment>
                )}
              </article>
            ))}
          </div>
        </div>
        <div sx={{ my: 14 }}>
          <Styled.h3>{get('approach.title')}</Styled.h3>
          <MarkdownContent sx={{ mt: 7 }}>
            {get('approach.text')}
          </MarkdownContent>
        </div>
        <section>
          <Card
            imageKey={get('card.imageKey')}
            imageLabel={get('card.imageLabel')}
            path={get('card.path')}
          />
        </section>
      </Container>
    </section>
  </Fragment>
);

const MarketsPage = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
  } = data;
  const lang = useCurrentLanguage();

  return (
    <Layout
      page="markets"
      seo={{
        title: frontmatter.pageTitle,
        description: frontmatter.description,
      }}
    >
      <MarketsPageTemplate lang={lang} get={createGet(frontmatter)} />
    </Layout>
  );
};

MarketsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default MarketsPage;

export const pageQuery = graphql`
  query MarketsPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        description
        challenges {
          title
          text
        }
        solutions {
          title
          text
          modules {
            title
            text
            imageKey
          }
        }
        approach {
          title
          text
        }
        card {
          imageLabel
          imageKey
          path
        }
      }
    }
  }
`;
